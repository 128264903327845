<template>
  <section class="tableNovelties">
    <div class="tableNovelties__wrapper">
      <div class="tableNovelties__optionSelected " gcard v-if="selectedsCard.length">
        <span class="tableNovelties__text">
          <div class="tableNovelties__inputAll">
            <input type="checkbox" class="mycheck tableNovelties__checkbox " id="all" v-model="all" @click="selectAll" />
          </div>
          <h3 class="tableNovelties__text text">{{ selectedsCard.length }} ELEMETOS SELECCIONADOS</h3>
        </span>
        <span class="tableNovelties__icons">
          <button class="tableNovelties__icon close" @click="actionsModals('rejected')">
            <iconic name="close"></iconic>
          </button>
          <button class="tableNovelties__icon aprove" @click="actionsModals('approved')">
            <iconic name="aprove"></iconic>
          </button>
          <button class="tableNovelties__icon edit" @click="actionsModals('edit')">
            <iconic name="edit"></iconic>
          </button>
        </span>
      </div>
      <div class="tableNovelties__cardsNovelties" :key="bindCard">
        <div class="spinner" v-if="isLoading"></div>
        <div class="tableNovelties__header">
          <div v-for="(item, index) in HeaderListNovelties" :key="index" class="tableNovelties__itemList">{{ item }}</div>
        </div>
        <cardNoveltiesAdmin v-for="(info, idx) in usersData" :key="idx" :add="add" :remove="remove" :modify="modifyTime" :selected="selected" :cardData="info"></cardNoveltiesAdmin>
      </div>
      <div class="">
        <ModalVue v-if="showModalEdit && selectedsCard.length" @onCancel="showModalEdit = false">
          <template slot="header">
            <div class="modal-contentEdit">
              <h3>NOVEDADES DE MODELOS SELECCIONADAS</h3>
              <div class="modal-contentEdit-wrapper">
                <div class="modal-contentEdits" v-for="(data, idx) in selectedsCard" :key="idx">
                  <div class="">
                    <avatar class="modal-contentEdit__avatar" :user="data.assignament"></avatar>
                  </div>
                  <div class="modal-contentEdit__name">
                    <span>{{ data.assignament.user + " " + data.assignament.city + "-" + data.assignament.office }}</span>
                    <div>{{ getTyopeNovelties(data.type) ? getTyopeNovelties(data.type).name : "" }}</div>
                  </div>
                  <div class="modal-contentEdit__item-time">
                    <iconic name="clock_solid"></iconic>
                    <span>{{ (data.average || 0) + " MIN" }}</span>
                  </div>
                  <button class="modal-contentEdit__close" v-if="selectedsCard.length > 1" @click="remove(data)">
                    <iconic name="close"></iconic>
                  </button>
                </div>
              </div>
              <span class="modal-contentEdit__itemsTime" v-if="showTime">
                <label for="tiempo">Tiempo</label>
                <div class="modal-contentEdit__itemsTime-time">
                  <iconic name="clock_solid"></iconic>
                  <input
                    class="modal-contentEdit__itemsTime-input"
                    v-model="timeModels"
                    id="tiempo"
                    type="number"
                    min="0"
                    @keydown="blockInvalidChars"
                    placeholder="Escribe el tiempo en minutos Ejm: 15"
                  />
                  <p>MIN</p>
                </div>
              </span>
              <div class="modal-contentEdit__description">
                <textarea type="text" placeholder="Descripción detallada" v-model="description" />
              </div>
            </div>
            <div class="modal-contentEdit-buttons">
              <button class="btn btn-cancel" @click="closeModal()">Cancelar</button>
              <button class="btn btn-save" @click="toSaveAction" :disabled="disabledBtn">{{ textTosave }}</button>
            </div>
          </template>
          <template slot="footer">
            <div></div>
          </template>
        </ModalVue>
      </div>
    </div>
  </section>
</template>

<script>
  import cardNoveltiesAdmin from "./AdminCardNovelies.vue";
  import mixincrudNovelties from "./mixinCrudNovelties";
  import ModalVue from "../modals/Modal.vue";
  import InfoNovelties from "./UserInfoNovelties.vue";
  export default {
    props: ["usersData"],
    mixins: [mixincrudNovelties],
    components: {
      cardNoveltiesAdmin,
      InfoNovelties,
      ModalVue,
    },
    data() {
      return {
        page: 0,
        isLoading: false,
        hasMore: false,
        showModalEdit: false,
        openModalEdit: false,
        all: false,
        selected: false,
        showTime: false,
        selectTurn: "",
        selecte: [],
        selectedsCard: [],
        description: "",
        timeOut: "",
        bindCard: 0,
        timeModels: "",
        textTosave: "Guardar",
      };
    },
    computed: {
      disabledBtn() {
        if (["Aprobar", "Rechazar"].includes(this.textTosave) && this.description !== "") {
          return false;
        }
        return this.description === "" || this.timeModels === "";
      },
    },
    watch: {
      selectedsCard(newValue) {
        this.description = "";
        this.timeModels = "";

        newValue.forEach((data) => {
          this.updateModalData(data);
        });
      },
    },
    methods: {
      blockInvalidChars(event) {
        const invalidKeys = ["-", "+", "e", ",", ".", "NumpadSubtract", "NumpadAdd"];
        if (invalidKeys.includes(event.key)) {
          event.preventDefault();
        }
      },
      updateModalData(data) {
        this.description = data.description || "";
        this.timeModels = data.average || "";
      },
      add(info) {
        if (this.selectedsCard.length < this.usersData.length) {
          this.selectedsCard = [...this.selectedsCard, info];
          this.all = false;
        }
        if (this.selectedsCard.length === this.usersData.length) {
          this.all = true;
        }
      },

      remove(info) {
        this.selectedsCard = this.selectedsCard.filter((data) => data._id !== info._id);
        this.all = false;
      },

      deleteUpdates() {
        this.selectedsCard.forEach((element) => {
          this.usersData.splice(this.usersData.indexOf(element), 1);
          this.selectedsCard = [];
        });
      },

      toSaveAction() {
        if (this.textTosave == "Aprobar") this.actionStatus("approved");
        else if (this.textTosave == "Rechazar") this.actionStatus("rejected");
        else this.save();
      },

      selectAll() {
        this.all = !this.all;
        if (this.all) {
          this.selectedsCard = this.usersData;
          this.selected = true;
        } else {
          this.selectedsCard = [];
          this.selected = false;
        }
        this.bindCard = 0;
      },

      closeModal() {
        this.selected = true;
        this.showModalEdit = false;
        this.description = "";
        this.all = false;
        setTimeout(() => {
          this.selectedsCard = [];
          this.selected = false;
        }, 50);
      },

      actionsModals(toDo) {
        if (toDo === "edit") {
          this.showTime = true;
          this.timeModels = "";
          this.textTosave = "Guardar";
        }
        if (toDo === "approved") {
          this.textTosave = "Aprobar";
          this.showTime = false;
          this.timeModels = "";
        }
        if (toDo === "rejected") {
          this.textTosave = "Rechazar";
          this.showTime = false;
          this.timeModels = "";
        }
        this.description = "";
        this.showModalEdit = true;
      },

      async save() {
        this.isLoading = true;
        let [body, AloneUpdate] = [{}, {}];
        if (this.selectedsCard.length === 1) {
          body = {
            _id: this.selectedsCard[0]._id,
            average: this.timeModels,
            description: this.description,
          };
        } else {
          AloneUpdate = this.selectedsCard.map((e) => {
            return { _id: e._id, date: e.date };
          });
          body = {
            body: AloneUpdate,
            options: {},
          };
          if (this.timeModels) body.options.average = this.timeModels;
          if (this.description) body.options.description = this.description;
        }
        const response = await this.updateNovelties(body);

        if (response.error) {
          return this.showError(response.message);
        }
        if (response.update?.isEnable) {
          this.usersData.splice(this.usersData.indexOf(this.selectedsCard[0]), 1, response.update);
        } else {
          this.usersData.forEach((element) => {
            response?.update?.forEach((e) => {
              if (element?._id === e?._id) {
                this.usersData.splice(this.usersData.indexOf(element), 1, e);
              }
            });
          });
        }
        this.closeModal();
        this.showSuccess("Actulialización Correcta");
        this.isLoading = false;
      },

      modifyTime($event) {
        this.selectedsCard = [$event];
        this.showModalEdit = true;
        this.showTime = true;
        this.timeModels = "";
        this.textTosave = "Guardar";
      },
      modifyStatus($event) {
        this.selectedsCard = [$event];
        this.showModalEdit = true;
        this.showTime = false;
        this.timeModels = "";
        this.textTosave = "Aprobar";
      },
      async actionStatus(status) {
        const body = { body: this.selectedsCard, options: { status: status, description: this.description } };
        await this.updateNovelties(body);
        this.bindCard++;
        this.deleteUpdates();
        this.closeModal();
      },
    },
    beforeMount() {},
  };
</script>

<style lang="scss">
  .tableNovelties {
    &__header {
      margin-left: 60px;
      @include Flex();
    }
    &__itemList {
      width: 190px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      color: #4a4a4a;
      padding: 10px 0px;
      &:nth-child(4) {
        width: 240px;
        padding-left: 30px;
      }
      &:nth-child(6) {
        width: 230px;
        padding-left: 30px;
      }
      &:nth-child(7) {
        width: 110px;
      }
    }
    &__cardsNovelties {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      overflow: auto;
      padding: 0 16px;
    }

    &__optionSelected,
    &__text {
      display: flex;
      justify-content: space-between;
    }
    &__checkbox {
      border-radius: 5px;
      border: 1px solid #c4c4c4;
    }
    &__text {
      .text {
        padding: 5px;
        font-size: 14px;
        font-weight: bold;
        color: #8c8c8c;
      }
    }

    .modal {
      &-contentEdit {
        max-width: 400px;
        width: 100%;
        max-height: 500px;
        &__itemsTime {
          &-time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &-time {
              &-icon {
                margin-right: 10px;
              }
            }
            width: 100%;
            border-radius: 6px;
            padding: 6px 8px;
            box-shadow: 0px 0.5px 2px 0px #00000069;
          }
          &-input {
            width: 100%;
            margin: 0 0 0 10px;
            border: none;
          }
        }
        &__name {
          width: 180px;
          max-height: 40px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &-wrapper {
          overflow: auto;
          max-height: 280px;
        }
        &__avatar {
          width: 35px;
          height: 35px;
        }
        &__item {
          &-time {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 79.08px;
            height: 23.26px;
            border-radius: 5px;
            border: solid 1px #bd0909;
            color: #bd0909;
            img {
              margin-right: 10px;
            }
          }
        }
        &__close {
          border: none;
          color: $primary-color;
          background: none;
          font-size: 9px;
          font-weight: 800;
          font-weight: bold;
        }
        &-buttons {
          .btn {
            border: 1px solid $primary-color;
            padding: 5px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 800;
            margin: 0 10px;
            cursor: pointer;
            &-cancel {
              background-color: white;
              &:hover {
                background-color: rgb(255, 255, 255);
                color: black;
              }
              color: $primary-color;
            }
            &-save {
              background-color: $primary-color;
              &:hover {
                background-color: rgb(255, 0, 0);
                color: white;
              }
              color: white;
            }
          }
        }
        &__description {
          margin-top: 10px;
          textarea {
            width: 100%;
            border-radius: 6px;
            padding: 6px 8px;
            box-shadow: 0px 0.5px 2px 0px #00000069;
            outline: none;
            border: none;
          }
        }
      }

      &-contentEdits {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #eaeaea;
        padding: 5px;
        border-radius: 5px;
        margin-bottom: 5px;
        border-radius: 1px solid #f5f5f5;
        font-size: 12px;
        &__avatar {
          width: 34px;
          height: 34px;
        }
        max-height: 41px;
        max-width: 400px;
      }
    }
    &__icons {
      display: flex;
    }
    &__containerFilter {
      display: flex;
      margin-bottom: 15px;
    }
    &__icon {
      display: flex;
      border: none;
      margin: 0 5px 0 0;
      justify-content: center;
      align-items: center;
      color: #fff;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      font-size: 12px;
      cursor: pointer;
    }
    .close {
      background-color: #bd0909;
      &:hover {
        color: black;
        background-color: #bd0909d5;
      }
    }
    .aprove {
      background-color: #0fa90c;
      &:hover {
        color: black;
        background-color: #0fa90cde;
      }
    }
    .edit {
      background-color: #00a0ff;
      &:hover {
        background-color: #00b0ff;
      }
    }
  }
</style>
