<script>
  import Search from "./SearchModels.vue";
  import mixinCrudNovelties from "./mixinCrudNovelties";

  export default {
    props: {
      error: {
        type: Boolean,
        default: false,
      },
      update: {
        type: Boolean,
        default: false,
      },
    },
    components: { Search },
    mixins: [mixinCrudNovelties],
    data() {
      return {
        title: "Novedades",
        optionTime: "am",
        optionTimeEnd: "pm",
        selectedHourInit: 0,
        selectedMinInit: "00",
        selectedHourEnd: 0,
        selectedMinEnd: "00",
        dateInit: "",
        dateEnd: "",
        selectedNoveltie: "",
        description: "",
        dataModel: {},
      };
    },
    watch: {
      update(newVal) {
        if (newVal) {
          this.selectedHourEnd = 0;
          this.selectedMinEnd = "00";
          this.optionTimeEnd = "pm";
          this.dateEnd = "";
          setTimeout(() => {
            this.$emit("updateError", false);
          }, 3000);
        }
      },
    },
    computed: {
      filteredNovietis() {
        return this.typesNovietis.filter((objeto) => ["Incapacidad", "Calamidad"].includes(objeto.name));
      },
      initNoveltie() {
        const dateString = `${this.selectedHourInit}:${this.selectedMinInit}${this.optionTime}`;
        const timeParts = dateString.match(/(\d+):(\d+)(am|pm)/);
        const hour = parseInt(timeParts[1]);
        const minute = parseInt(timeParts[2]);
        const period = timeParts[3];
        const currentDate = new Date(this.dateInit);
        if (period === "pm" && hour !== 12) {
          currentDate.setHours(hour + 36);
        } else if ((period === "am" && hour < 12) || (period === "pm" && hour === 12)) {
          currentDate.setHours(hour + 24);
        } else {
          if (period === "am" && hour === 12) {
            currentDate.setHours(hour + 12);
          } else {
            currentDate.setHours(hour);
          }
        }
        currentDate.setMinutes(minute);
        return currentDate;
      },
      endNoveltie() {
        const dateString = `${this.selectedHourEnd}:${this.selectedMinEnd}${this.optionTimeEnd}`;
        const timeParts = dateString.match(/(\d+):(\d+)(am|pm)/);
        const hour = parseInt(timeParts[1]);
        const minute = parseInt(timeParts[2]);
        const period = timeParts[3];
        const currentDate = new Date(this.dateEnd);
        if (period === "pm" && hour !== 12) {
          currentDate.setHours(hour + 12);
        } else if (period === "am" && hour === 12) {
          currentDate.setHours(0);
        } else {
          currentDate.setHours(hour);
        }
        currentDate.setMinutes(minute);

        return currentDate;
      },
      btnSendDisabled() {
        return !this.selectedNoveltie || !this.description || !this.dateInit || !this.dateEnd || !this.dataModel._id;
      },
    },
    methods: {
      recibir(filteredModel) {
        this.dataModel = filteredModel;
      },
      validateHour(propertyName, maxValue) {
        let numero = parseInt(this[propertyName], 10);
        if (isNaN(numero) || this[propertyName] === "" || numero < 1) {
          numero = 0;
        } else if (numero > maxValue) {
          numero = maxValue;
        }
        this[propertyName] = numero.toString().padStart(2, "0");
      },
      validateNumber(propertyName) {
        let numero = parseInt(this[propertyName], 10);
        numero = isNaN(numero) || this[propertyName] === "" || numero < 1 ? 0 : numero;
        numero = Math.min(Math.max(numero, 0), 60);
        this[propertyName] = numero.toString().padStart(2, "0");
      },
      clickCancel() {
        this.$emit("onCancel");
      },
      clickSubmit() {
        const dataSend = {
          isEnable: this.selectedNoveltie.isEnable,
          whoCreate: this.$user._id,
          status: "pending",
          type: this.selectedNoveltie._id.$oid,
          description: this.description,
          date: this.initNoveltie,
          dateTo: this.endNoveltie,
          assignament: this.dataModel._id,
          city: this.dataModel.city,
          office: this.dataModel.office,
          room: this.dataModel.room,
          initTransmision: this.initNoveltie,
          endTransmision: this.endNoveltie,
        };
        this.$emit("onSubmit", dataSend);
      },
    },
  };
</script>
<template>
  <section class="novelties-admin">
    <div class="novelties-admin__container">
      <h1 class="novelties-admin__container--title">{{ title }}</h1>
      <a @click="clickCancel" class="novelties-admin__container--close"><iconic name="close"></iconic></a>
    </div>
    <Search @onFilter="recibir" />
    <select v-model="selectedNoveltie" class="novelties-admin__options">
      <option value="" disabled selected>Selecciona la novedad</option>
      <option v-for="(option, index) in filteredNovietis" :value="option" :key="index">{{ option.name }}</option>
    </select>
    <div class="novelties-admin__date">
      <div class="novelties-admin__date--start">
        <label class="date--label">Inicio de novedad</label>
        <div class="container--date">
          <input type="date" class="date--input" v-model="dateInit" />
          <input class="date--input" type="number" v-model="selectedHourInit" @input="validateHour('selectedHourInit', 12)" /> :
          <input class="date--input" type="number" v-model="selectedMinInit" @input="validateNumber('selectedMinInit')" />
          <select class="date--options" v-model="optionTime">
            <option value="am">AM</option>
            <option value="pm">PM</option>
          </select>
        </div>
      </div>
      <div class="novelties-admin__date--end">
        <label class="date--label">Final de novedad</label>
        <div class="container--date">
          <input type="date" class="date--input" v-model="dateEnd" />
          <input class="date--input" type="number" v-model="selectedHourEnd" @input="validateHour('selectedHourEnd', 12)" /> :
          <input class="date--input" type="number" v-model="selectedMinEnd" @input="validateNumber('selectedMinEnd')" />
          <select class="date--options" v-model="optionTimeEnd">
            <option value="am">AM</option>
            <option value="pm">PM</option>
          </select>
        </div>
      </div>
    </div>
    <span v-if="error" class="novelties-admin__error">La fecha final no debe ser menor a la de inicio.</span>
    <textarea class="novelties-admin__text" name="" id="" cols="37" rows="6" v-model="description"></textarea>
    <div class="novelties-admin__btn">
      <button class="btn" @click="clickSubmit" :disabled="btnSendDisabled">Crear</button>
    </div>
  </section>
</template>
<style lang="scss">
  .novelties-admin {
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    border: 1px solid #e2e2e2;
    max-width: 400px;
    width: 100%;
    height: max-content;
    border-radius: 12px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    &__container {
      display: flex;
      padding-bottom: 5px;
      justify-content: space-between;
      border-bottom: 1px solid #e2e2e2;
      &--close {
        color: $primary_color;
        font-weight: 900;
        cursor: pointer;
        padding-top: 7px;
      }
    }
    &__options {
      width: 100%;
      height: 35px;
      outline: none;
      margin: 0 0 10px 0;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
    }
    &__date {
      &--start,
      &--end {
        .date--label {
          font-size: 18px;
          font-weight: 700;
        }
        .container--date {
          :nth-child(1) {
            width: 146px;
          }
        }
        padding: 5px 0;
      }
      .date--input,
      .date--options {
        width: 60px;
        height: 30px;
        border-radius: 6px;
        margin-right: 5px;
        border: 1px solid #e2e2e2;
        text-align: center;
      }
    }
    &__text {
      margin: 10px 0;
      padding: 2px 5px;
      outline: none;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
    }
    &__btn {
      text-align: center;
      .btn {
        font-family: $first_font;
        width: 150px;
        outline: none;
        border: none;
        font-size: 16px;
        padding: 6px 16px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.36);
        border-radius: 6px;
        color: white;
        background-color: $primary_color;
        border: none;
        margin: 10px;
      }
    }
    &__error {
      color: $primary-color;
    }
  }
</style>
