<template>
  <div>
    <section class="info">
      <div class="info__model">
        <h1>Historico</h1>
        <a class="info__model--close" @click="clickCancel"><iconic name="close"></iconic></a>
      </div>
      <div class="info__user">
        <div class="info__user--container">
          <div class="info__user--user">
            <div class="info__user--circle">
              <img class="info__user--imagen" :src="noveltie.assignament.picture" :alt="noveltie.assignament.artisticName" />
            </div>
            <div class="info__user--name">
              <p class="info__user--title">{{ noveltie.assignament.artisticName }}</p>
              <div class="info__user--calendar">
                <i class="fas fa-calendar-alt info__model--dates cal"></i>
                <span class="info__model--dates">{{ convertDate(extracDate()[0]) }}</span>
                <span class="info__model--dates">{{ convertDate(extracDate()[1]) }}</span>
              </div>
            </div>
          </div>
          <div class="info__user--times">
            <div class="info__user--data">{{ changeTime(noveltie.timeBreak) }}</div>
            <div class="info__user--data">{{ changeTime(noveltie.totalTimeApproved) }}</div>
            <div class="info__user--data">{{ changeTime(noveltie.totalTime) }}</div>
          </div>
        </div>
        <div class="info__titles">
          <li class="info__titles--column" v-for="(title, index) in titlesModalDataInfo" :key="index">{{ title }}</li>
        </div>
      </div>
      <div class="info__data">
        <div v-if="isLoading" class="loader">
          <div v-for="index in 2" :key="index" class="skeleton">
            <!-- Contenido de cada div -->
            Cargando...
          </div>
        </div>
        <div
          class="info__data--elem"
          v-for="(date, index) in arrayDates"
          :key="index"
          :class="{ even: index % 2 === 0, odd: index % 2 !== 0 }"
          @click="openDetail(date, $event)"
        >
          <div class="fila">{{ date }}</div>
          <div class="fila">{{ noveltie.assignament.room }}</div>
          <div class="fila">{{ noveltie.assignament.workShift.name }}</div>
          <div class="fila">{{ changeTime(showTimeBreak(dataForDates[date])) }}</div>
          <div class="fila">{{ changeTime(timeApproved(dataForDates[date])) }}</div>
          <div class="fila">{{ changeTime(totalTime(dataForDates[date])) }}</div>
          <div v-if="showDetail && selectedDate === date" class="show">
            <DetailHistory :details="details" class="modalDetails"></DetailHistory>
          </div>
        </div>
      </div>
    </section>
    <div class="info__mask">mascara</div>
  </div>
</template>
<script>
import mixinCrudNovelties from "./mixinCrudNovelties";
import DetailHistory from "./AdminDetailHistory.vue";
export default {
  props: ["noveltie"],
  mixins: [mixinCrudNovelties],
  components: { DetailHistory },
  data() {
    return {
      data: "",
      arrayDates: [],
      dataForDates: {},
      details: [],
      showDetail: false,
      selectedDate: null,
      isLoading: true,
    };
  },
  methods: {
    closeDetail() {
      this.showDetail = false;
    },
    async openDetail(data, event) {
      let query = `status=history&date=${data}&dateTo=${data}&idUser=${this.noveltie.assignament._id}`;
      this.showDetail = true;
      if (this.showDetail) {
        this.isLoading = true;
        this.details = await this.getNoveltiesDetail(query);
        this.isLoading = false;
      }
      this.selectedDate = data;

      const container = event.target.closest(".info__data--elem");

      if (this.showDetail) {
        container.style.backgroundColor = "#f7fbfd";
      } else if (!this.showDetail && container.classList[1] === "even") {
        container.style.backgroundColor = `#f5f5f5`;
      } else {
        container.style.backgroundColor = `#fff`;
      }
    },
    convertDate(date) {
      let time = date
        .split("-")
        .reverse()
        .join("-");

      return time;
    },
    showTimeBreak(data) {
      let value = 0; // Establece el valor inicial en cero
      if (data) {
        data?.forEach((element) => {
          if (element.type === "628cee2c87c76adae90cf84d") {
            const elementValue = parseFloat(element.average);
            if (!isNaN(elementValue)) {
              value = elementValue;
            }
          }
        });
      }

      return value;
    },
    timeApproved(data) {
      const result = data.reduce((accumulator, currentValue) => {
        if (
          currentValue.status === "approved" &&
          currentValue.type !== "628cee2c87c76adae90cf84d" &&
          currentValue.type !== "627aca008e84d13c1f2dee94"
        ) {
          accumulator += currentValue.average;
        }
        return accumulator;
      }, 0);
      return result;
    },
    totalTime(data) {
      const result = data.reduce((accumulator, currentValue) => {
        if (currentValue.status === "approved" && currentValue.type !== "628cee2c87c76adae90cf84d") {
          accumulator += currentValue.average;
        }
        return accumulator;
      }, 0);
      return result;
    },
    async getDataDay(data) {
      let query = `status=history&date=${data}&dateTo=${data}&idUser=${this.noveltie.assignament._id}`;
      let res = await this.getNoveltiesDetail(query);
      this.isLoading = false;
      return res;
    },
    clickCancel() {
      this.$emit("onCancel");
    },
    extracDate() {
      let queryString = this.setQuery();
      let regex = /\d{4}-\d{2}-\d{2}/g;
      let fechasEncontradas = queryString.match(regex);
      return fechasEncontradas;
    },
    generateDatesBetween() {
      const startDate = new Date(this.extracDate()[0]);
      const endDate = new Date(this.extracDate()[1]);
      const datesBetween = [];

      while (startDate <= endDate) {
        datesBetween.push(startDate.toISOString().split("T")[0]);
        startDate.setDate(startDate.getDate() + 1);
      }
      return datesBetween;
    },
  },
  async mounted() {
    this.arrayDates = this.generateDatesBetween();
    for (const date of this.arrayDates) {
      this.$set(this.dataForDates, date, await this.getDataDay(date));
    }
  },
};
</script>
<style lang="scss">
.info {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-40%, -51%);
  z-index: 100;
  border: 1px solid #e2e2e2;
  max-width: 1270px;
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.144);
  }

  &__model {
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    border-bottom: 2px solid lightgrey;
    &--dates {
      margin-right: 15px;
    }
    &--close {
      cursor: pointer;
      color: $trout;
      margin-top: 10px;
    }
  }

  &__user {
    &--container {
      display: flex;
      padding: 15px 20px;
    }
    &--user {
      width: 500px;
      display: flex;
    }
    &--circle {
      border: 3px solid $primary_color;
      border-radius: 50%;
      width: 39px;
      height: 39px;
    }
    &--imagen {
      position: relative;
      top: -1px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
    &--name {
      margin-top: -10px;
      margin-left: 5px;
    }
    &--title {
      font-size: 20px;
      text-transform: capitalize;
      padding-left: 15px;
    }
    &--calendar {
      padding-left: 15px;
    }
    &--times {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-left: 80px;
      :nth-child(1) {
        height: 50px;
        padding: 8px 15px 15px 15px;
        margin-top: 15px;
        border-radius: 6px;
        height: 40px;
        background-color: rgba(255, 165, 0, 0.5);
        &::before {
          content: "T. Break ";
          font-weight: bold; /* Aplicar negrita al texto */
        }
      }
      :nth-child(2) {
        padding: 8px 15px 15px 15px;
        margin-top: 15px;
        border-radius: 6px;
        height: 40px;
        background: rgba(94, 188, 240, 0.6);
        &::before {
          content: "T. Reposicion ";
          font-weight: bold; /* Aplicar negrita al texto */
        }
      }
      :nth-child(3) {
        padding: 8px 15px 15px 15px;
        margin-top: 15px;
        border-radius: 6px;
        height: 40px;
        background: rgba(0, 179, 0, 0.3);
        &::before {
          content: "T. Total ";
          font-weight: bold; /* Aplicar negrita al texto "T. Break" */
        }
      }
    }
  }

  &__titles {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    font-size: 18px;
    border-top: 1px solid #d1caca;
    border-left: 1px solid #d1caca;
    border-right: 1px solid #d1caca;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 0 20px;
    padding-top: 15px;
    font-weight: 700;

    :nth-child(1) {
      padding-left: 30px;
    }
    :nth-child(2) {
      padding-left: 20px;
    }
    :nth-child(3) {
      padding-left: 25px;
    }
    :nth-child(4) {
      padding-left: 25px;
    }
    :nth-child(5) {
      padding-left: 5px;
    }
    :nth-child(6) {
      padding-left: 25px;
    }
    &--column {
      list-style: none;
      margin-right: 80px;
      margin-bottom: 7px;
    }
  }

  &__data {
    border-left: 1px solid #d1caca;
    border-right: 1px solid #d1caca;
    border-bottom: 1px solid #d1caca;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0 20px;
    margin-bottom: 20px;
    max-height: 580px;
    height: max-content;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
    }
    .even {
      background-color: #f5f5f5;
    }
    .odd:hover {
      background-color: #f5f5f5;
    }
    &--elem {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      cursor: pointer;
      padding-left: 10px;
      padding-top: 5px;
      .fila {
        width: 140px;
        height: 35px;
        padding-left: 25px;
      }
      .show:nth-child(7) {
        grid-column: span 6;
      }
    }
  }
  .cal {
    color: $trout;
  }
  .skeleton {
    // background: linear-gradient(90deg, rgb(100, 39, 39) 25%, #e0e0e0 50%, rgb(163, 66, 66) 75%);
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    color: transparent; /* Hacemos el texto transparente */
    position: relative; /* Necesario para alinear el texto "Cargando" */
  }

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
}
</style>
