<template>
  <section class="cardHistoryNolveties" :set="((assing = noveltie.assignament), (who = noveltie.whoCreate))">
    <div class="cardHistoryNolveties__wrapper" @click="openModal">
      <div class="cardtableNovelties__avatarC">
        <avatar class="cardtableNovelties__avatar" :user="assing"></avatar>
        <p class="cardtableNovelties__name">
          {{ assing.user }}
        </p>
      </div>
      <div bold class="cardtableNovelties__item-time">{{ noveltie.room || "Sin Habitación" }}</div>
      <div class="cardtableNovelties__item-turn" :color="turn[assing.workShift.name]">
        {{ turn[assing.workShift.name] }}
      </div>
      <div class="cardtableNovelties__item-time" :statusTime="info" v-for="(info, idx) of time" :key="idx">
        <iconic name="clock_solid"></iconic>
        <span>
          {{ changeTime(noveltie[info]) }}
        </span>
      </div>
    </div>
    <Modal v-if="showModalDate" @onCancel="closeModal" :noveltie="noveltie" />
    <div v-if="showDetail">
      <detailHistory :details="details" v-if="!isLoading"></detailHistory>
      <div class="spinner" v-if="isLoading"></div>
    </div>
  </section>
</template>

<script>
  import mixinCrudNovelties from "./mixinCrudNovelties";
  import detailHistory from "./AdminDetailHistory.vue";
  import Modal from "./ModalDateInfo.vue";

  export default {
    props: ["noveltie"],
    name: "cardHistoryNoveties",
    mixins: [mixinCrudNovelties],
    components: { detailHistory, Modal },

    data() {
      return {
        status: {
          approved: "Aprobado",
          rejected: "Rechazado",
        },
        time: ["timeBreak", "totalTimeApproved", "totalTimeRejected", "totalTime"],
        showDetail: false,
        isLoading: false,
        details: [],
        showModalDate: false,
      };
    },
    methods: {
      async openDetail() {
        this.showDetail = !this.showDetail;
        if (this.showDetail) {
          this.isLoading = true;
          this.details = await this.getNoveltiesDetail(this.setQuery() + `&idUser=${this.noveltie.assignament._id}`);
          this.isLoading = false;
        }
      },
      openModal() {
        this.showModalDate = true;
      },
      closeModal() {
        this.showModalDate = false;
      },
    },
    mounted() {},
  };
</script>

<style lang="scss">
  .cardHistoryNolveties {
    width: 100%;
    text-align: left;
    &__wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      padding: 10px 5px;
      &:hover {
        background: #f5f5f5;
      }
    }
    .cardtableNovelties {
      &__item {
        &-detail {
          display: flex;
          align-items: center;
          color: #5b5957;
          cursor: pointer;
          &--icon {
            &:hover {
              color: $primary-color;
            }
          }
        }
        &--show {
          margin-left: 15px;
        }
      }
      &__name {
        margin-left: 6px;
        text-overflow: ellipsis;
        width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-transform: capitalize;
      }
      &__avatarC {
        width: 300px;
      }
      &__avatar {
        width: 35px;
        height: 35px;
      }
    }
    [statusTime] {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: max-content;

      border-radius: 5px;
      padding: 0 5px;
      border: solid 1px $primary-color;
      color: $primary-color;
      .iconic {
        margin: 3px 2px;
      }
    }
    [color] {
      margin-top: 8px;
      padding: 2px 10px;
      color: white;
      border-radius: 5px;
      text-transform: capitalize;
      height: 25px;
      width: 85px;
    }
    [statusTime="timeBreak"] {
      color: orange;
      border: solid 1px orange;
      white-space: nowrap;
    }
    [statusTime="totalTimeApproved"] {
      color: #5ebcf0;
      border: solid 1px #5ebcf0;
      white-space: nowrap;
    }
    [statusTime="totalTimeRejected"] {
      color: #ff0000;
      border: solid 1px #ff0000;
      white-space: nowrap;
    }
    [statusTime="totalTime"] {
      color: #00b300;
      border: solid 1px #00b300;
      white-space: nowrap;
    }
    [color="Tarde"] {
      background-color: #efb880;
      text-align: center;
    }
    [color="Noche"] {
      background-color: #b4afaf;
      text-align: center;
    }
    [color="Mañana"] {
      background-color: #50a2da;
      text-align: center;
    }
  }
</style>
