<template>
  <section class="detailHistory">
    <div class="detailHistory__wrapper">
      <div class="detailHistory__title">
        <div bold class="detailHistory__title-item" v-for="(data, idx) in titlesTable" :key="idx">
          {{ data }}
        </div>
      </div>
      <div class="detailHistory__card">
        <div
          class="detailHistory__card-texts"
          v-for="(data, idx) in details"
          :key="idx"
          :set="((monitor = data.whoCreate || defaultUser), (aprove = data.whoUpdate))"
          @click="showModalData(data)"
        >
          <p class="detailHistory__p">
            {{ monitor.user }}
          </p>
          <p class="detailHistory__p">{{ (data.city || "") + "-" + (data.office || "") }}</p>
          <p class="detailHistory__p">{{ createStringDate(data) }}</p>
          <p class="detailHistory__p validator">
            <span class="detailHistory__userEye">
              {{ aprove ? aprove.user : "Automatico " }}
            </span>
            <span
              :class="[
                data.type === '628cee2c87c76adae90cf84d'
                  ? 'detailHistory__break'
                  : data.type === '627aca008e84d13c1f2dee94'
                  ? 'detailHistory__time'
                  : data.status === 'approved'
                  ? 'detailHistory__approved'
                  : 'detailHistory__rejected',
              ]"
            >
              <iconic name="clock_solid"></iconic>
              {{ changeTime(data.average) }}
            </span>
          </p>
          <p class="detailHistory__p">{{ new Date(data.dateCreate).toISOString().split("T")[0] }}</p>
          <p class="detailHistory__p detailHistory__status" :statusTimer="data.status">{{ statusLists[data.status] }}</p>
        </div>
      </div>
    </div>

    <ModalVue v-if="showModal" class="details">
      <template v-slot:header>
        <h4>Detalle de la novedad</h4>
      </template>
      <template v-slot:body>
        <div class="detailModal">
          <div class="detailModal__noveltie">
            <span bold class="detailModal__title">
              Novedad:
            </span>
            <span class="detailModal__item">
              {{ getTyopeNovelties(modalData.type).name || "" }}
            </span>
          </div>
          <div class="detailModal__description">
            <div bold class="detailModal__title">Descripción:</div>
            <span class="detailModal__item">
              {{ modalData.description || "Sin descripción" }}
            </span>
          </div>
          <div class="detailModal__description">
            <div class="detailModal__item">
              <span bold>Soluciono:</span>
              <span>{{ " " + modalData.whoUpdate.user || "" }}</span>
            </div>
            <div class="detailModal__item">
              <span bold>Fecha solución:</span>
              <span>{{ new Date(modalData.date).toISOString().split("T")[0] || "" }}</span>
            </div>
          </div>
          <h6 class="detailModal__title">Historico de modificaciones:</h6>
          <div class="detailModal__logs">
            <div class="detailModal__logsItem" v-for="(data, idx) in logsDetail.novelties" :key="idx">
              <div class="detailModal__logs-wrapper">
                <div class="detailModal__items">
                  <span bold class="detailModal__title">Modifico:</span>
                  <span class="detailModal__item">
                    {{ data.whoCreate.user }}
                  </span>
                </div>
                <div>
                  <span bold class="detailModal__title">
                    Fecha:
                  </span>
                  <span class="detailModal__item">
                    {{ new Date(data.date).toISOString().split("T")[0] }}
                  </span>
                </div>
              </div>
              <div class="detailModal__content">
                <div bold class="detailModal__title">
                  Descripción:
                </div>
                <span class="detailModal__item">
                  {{ data.description }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button class="detailModal__button" @click="closeModal">Cerrar</button>
      </template>
    </ModalVue>
  </section>
</template>

<script>
import noveltiesCard from "./AdminCardNovelies.vue";
import mixinCrudNolvelties from "./mixinCrudNovelties";
import ModalVue from "../modals/Modal.vue";
export default {
  components: { noveltiesCard, ModalVue },
  mixins: [mixinCrudNolvelties],
  props: ["details"],
  data() {
    return {
      showModal: false,
      modalData: [],
      logsDetail: [],
      defaultUser: { user: "Automático", role: "superadmin" },
    };
  },
  methods: {
    async showModalData(modalData) {
      this.modalData = [];
      this.logsDetail = [];
      this.showModal = true;
      this.modalData = modalData;
      this.logsDetail = await this.getLogsDetailNoveties("_id=" + modalData._id);
    },
    closeModal() {
      this.showModal = false;
      this.modalData = [];
      this.logsDetail = [];
    },

    createStringDate(noveltie) {
      const [date, dateTo] = [new Date(noveltie.initTransmision), new Date(noveltie.endTransmision)];
      return `${date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()} - ${dateTo.getHours()}:${
        dateTo.getMinutes() < 10 ? "0" + dateTo.getMinutes() : dateTo.getMinutes()
      }`;
    },
  },
};
</script>

<style lang="scss">
.detailHistory {
  border: 1px solid #d1caca;
  margin: 0px 20px;
  margin-bottom: 25px;
  border-radius: 10px;
  background: #ffffff;
  .validator {
    display: flex;
    justify-content: space-between;
    padding-right: 85px;
  }

  &__title {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    padding: 10px 20px;
    font-size: 13px;
    border-bottom: 1px solid #d1caca;
    :nth-child(5) {
      margin-left: 50px;
    }
  }
  &__card {
    max-height: 300px;
    overflow: scroll;
    &-texts {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      font-size: 12px;
      margin-bottom: 4px;
      padding: 5px 20px;
      border-bottom: 1px solid #d1caca;
      :nth-child(4) {
        width: 250px;
      }
      :nth-child(5) {
        margin-left: 50px;
      }
    }
  }
  &__status {
    width: 80px;
    text-align: center;
    max-height: 22px;
  }
  &__detail {
    &:hover {
      cursor: pointer;
      color: #bd0909;
    }
  }
  &__p {
    font-size: 12px;
    display: flex;
    gap: 0 5px;
    align-content: center;
    text-align: center;
    flex-wrap: wrap;
  }

  [statusTimer="approved"] {
    color: #00b300;
    border: solid 1px #00b300;
  }
  [statusTimer="rejected"] {
    color: #bd0909;
    border: solid 1px #bd0909;
  }
  [statusTimer] {
    border-radius: 5px;
    padding: 2px 5px;
  }
  &__time {
    border-radius: 5px;
    padding: 2px 5px;
    color: #00b300;
    border: solid 1px #00b300;
  }
  &__break {
    border-radius: 5px;
    padding: 2px 5px;
    color: orange;
    border: solid 1px orange;
  }
  &__approved {
    border-radius: 5px;
    padding: 2px 5px;
    color: #5ebcf0;
    border: solid 1px #5ebcf0;
  }
  &__rejected {
    border-radius: 5px;
    padding: 2px 5px;
    color: #ff0000;
    border: solid 1px #ff0000;
  }
}
.detailModal {
  min-width: 280px;
  @include tabletWidth() {
    min-width: 450px;
  }
  &__logs {
    overflow: scroll;
    max-height: 150px;
  }
  &__title {
    font-size: 14px;
    font-weight: bold;
  }
  &__logsItem {
    padding: 5px;
    font-size: 12px;
    background-color: white;
    &:nth-child(odd) {
      background-color: rgba(226, 226, 226, 0.418);
    }
  }
  &__button {
    margin-top: 15px;
    color: #ffffff;
    background-color: $primary-color;
    border-radius: 5px;
    padding: 5px;
    border: none;
    font-weight: bold;
  }
}
.details {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
