<template>
  <section class="filterNovelties">
    <div class="filterNovelties__wrapper">
      <div class="filterNovelties__content">
        <div class="filterNovelties__inputs">
          <iconic class="filterNovelties__inputs-iconic" name="my_search"></iconic>
          <input class="filterNovelties__input" v-model="search" type="text" ginput name="search" placeholder="Buscar modelos" @input="changeQuery" />
        </div>
        <div class="filterNovelties__inputs">
          <button class="filterNovelties__selects-select" @click="onSearch()">
            <i class="fas fa-calendar-alt"></i>
            <span>
              {{ createStringDates }}
            </span>
          </button>
          <v-md-date-range-picker class="calendarColor" ref="rangeDates" @change="changeDates" :start-date="date" :end-date="dateTo"></v-md-date-range-picker>
        </div>
        <div class="filterNovelties__selects">
          <iconic class="filterNovelties__selects-iconic" name="filter"></iconic>
          <select class="filterNovelties__selects-select" name="selectTurn" v-model="selectTurn" ginput @input="changeQuery">
            <option value="">Turno</option>
            <option v-for="(data, idx) in turns" :key="idx" :value="data.value">{{ data.name }}</option>
          </select>
        </div>
        <div class="filterNovelties__selects" v-if="$userData.role !== 'coordinator'">
          <iconic class="filterNovelties__selects-iconic" name="filter"></iconic>
          <select class="filterNovelties__selects-select" name="city" v-model="city" ginput @input="changeQuery">
            <option value="">Ciudad</option>
            <option v-for="(data, idx) in $cities" :key="idx" :value="data">{{ data }}</option>
          </select>
        </div>
      </div>
      <div class="filterNovelties__deleteFilter" @click="showModal">
        Agregar Novedades
      </div>
      <div class="filterNovelties__deleteFilter" v-if="search || selectTurn || city" @click="deleteFilter">
        <i class="fa fa-times filterNovelties__deleteFilter-icon" aria-hidden="true"></i>
        Limpiar Filtros
      </div>
    </div>
    <Modal v-if="modal" @onCancel="showModal" @onSubmit="send" :error="error" :update="update" @updateError="handleUpdateError" />
  </section>
</template>

<script>
  import mixinCrudNovelties from "./mixinCrudNovelties";
  import Modal from "./ModalCreateNovietisAdmin.vue";
  import Swal from "sweetalert2";
  export default {
    name: "filtersNovelties",
    props: ["changeFilter", "showOther"],
    mixins: [mixinCrudNovelties],
    components: { Modal },
    data() {
      return {
        selectTurn: "",
        search: "",
        date: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split("T")[0],
        dateTo: new Date(new Date().setDate(new Date().getDate())).toISOString().split("T")[0],
        city: "",
        showPicker: false,
        modal: false,
        error: false,
        update: false,
      };
    },
    computed: {
      createStringDates() {
        const [dates, datesTo] = [this.date, this.dateTo];
        const lastTwoCharsDates = dates.slice(-2);
        const lastTwoCharsDatesTo = datesTo.slice(-2);
        const month = this.getShortMonth(dates);
        const monthTo = this.getShortMonth(datesTo);

        return `${lastTwoCharsDates} ${month} - ${lastTwoCharsDatesTo} ${monthTo}`;
      },
    },
    methods: {
      getShortMonth(date) {
        const AbbreviatedMonths = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];
        const [, month] = date.split("-");
        return AbbreviatedMonths[parseInt(month, 10) - 1];
      },
      handleUpdateError(value) {
        this.error = value;
      },
      async send(optionTime) {
        if (optionTime.dateTo < optionTime.date) {
          this.error = true;
          this.update = true;
          return;
        }
        await this.createNovelties(optionTime);
        const result = await Swal.fire({
          title: "Completado",
          text: "Novedad creada exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });

        if (result.isConfirmed) {
          this.showModal();
          this.modal = false;
        }
      },
      showModal() {
        this.modal = !this.modal;
        this.error = false;
        this.update = false;
      },
      deleteFilter() {
        this.search = "";
        this.date = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split("T")[0];
        this.dateTo = new Date(new Date().setDate(new Date().getDate())).toISOString().split("T")[0];
        this.selectTurn = "";
        this.city = "";
        const statusMoment = this.$route.query?.status;
        this.setFilter("reset");
        this.setFilter({ srcElement: { name: "status", value: statusMoment || "pending" } });
        this.changeFilter();
      },
      changeQuery(event) {
        this.setFilter(event);
        this.changeFilter();
      },

      onSearch() {
        this.$refs.rangeDates.togglePicker();
      },

      changeDates($event) {
        const [{ _d: start }, { _d: end }] = $event;
        this.date = new Date(start).toISOString().split("T")[0];
        this.dateTo = new Date(end).toISOString().split("T")[0];
        this.setFilter({ srcElement: { name: "date", value: this.date } });
        this.setFilter({ srcElement: { name: "dateTo", value: this.dateTo } });
        this.changeFilter();
      },
    },
    mounted() {
      Object.keys(this.$route.query).forEach((key) => {
        this[key] = this.$route.query[key];
      });
      if (!this.$route.query?.date || !this.$route.query?.dateTo) {
        this.setFilter({ srcElement: { name: "date", value: this.date } });
        this.setFilter({ srcElement: { name: "dateTo", value: this.dateTo } });
      }

      this.$nextTick(() => {
        const rangeDatesEl = this.$refs.rangeDates?.$el;
        const label = rangeDatesEl?.querySelector("label");
        const data = rangeDatesEl?.querySelector(".mdrp__activator");

        label && (label.innerHTML = "");
        data && (data.style.display = "none");
      });
    },
  };
</script>

<style lang="scss">
  .filterNovelties {
    display: flex;
    justify-content: space-between;
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
    &__inputs {
      display: flex;
      align-items: center;
      margin-right: 10px;
      position: relative;

      &-iconic {
        margin-right: 5px;
        position: absolute;
        top: 9.5px;
        left: 10px;
      }
    }
    &__input {
      padding: 5px 10px 5px 27px;
      background: #ffffff;
      border: 1px solid #c4c4c4;
      border-radius: 6px;
    }
    &__selects {
      position: relative;
      margin: 5px;
      width: 150px;
      &-select {
        text-transform: capitalize;
        appearance: none;
        text-align: start;
        outline: none;
        width: 100%;
        height: 33px;
        padding: 4px 8px 4px $mpadding * 2;
        color: black;
        background-color: #f0f0f0;
        border-radius: 4px;
        border: none;
        box-shadow: 0px 0.5px 2px 0px #00000069;
        &:hover {
          background-color: rgb(227, 224, 224);
        }
      }
      &-iconic {
        position: absolute;
        top: 9.5px;
        left: 10px;
      }
    }
    &__deleteFilter {
      color: $primary-color;
      cursor: pointer;
      padding: 5px 6px;
    }
    @media screen and (max-width: 768px) {
      &__inputs,
      &__selects {
        width: 100%;
        margin: 5px;
      }
    }
  }
</style>
