<template>
  <section class="search">
    <div class="search__container">
      <iconic name="my_search" class="search__container--icon" />
      <input class="search__container--input" placeholder="Buscar modelo" v-model="searchTerm" @input="filterModels" />
    </div>
    <div v-if="searchTerm && filteredModel" class="search__result">
      <div class="search__result--info">
        <img class="search__result--img" :src="filteredModel.picture" :alt="filteredModel.artisticName" />
        <p class="search__result--name">{{ filteredModel.artisticName }}</p>
      </div>
      <a @click="deleteSearch" class="search__result--close"><iconic name="close"></iconic></a>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      searchTerm: "",
    };
  },
  computed: {
    getModels() {
      let models = this.$store.state.user.userList;
      const onlyModels = models.filter((objet) => objet.role === "model");
      return onlyModels;
    },
    filteredModel() {
      return this.getModels.find((model) => model.artisticName.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
  },
  methods: {
    filterModels() {
      // We call this function every time the user writes to the input
      // to update the filteredModel.
      this.searchTerm !== "" ? this.$emit("onFilter", this.filteredModel) : this.$emit("onFilter", {});
    },
    deleteSearch() {
      this.searchTerm = "";
      this.filterModels();
    },
  },
  mounted() {
    this.$store.dispatch("user/getUsersList");
  },
};
</script>
<style lang="scss">
.search {
  padding: 15px 0px;
  position: relative;

  &__container {
    position: relative;

    &--input {
      height: 35px;
      width: 360px;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      padding-left: 35px;
    }

    &--icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  &__result {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 15px;
    background-color: #f3f3f3;
    width: 350px;
    border-radius: 20px;
    &--info {
      display: flex;
      gap: 20px;
      :nth-child(2) {
        padding: 6px 0 0 0;
      }
    }
    &--img {
      margin: 5px 0px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    &--close {
      cursor: pointer;
      padding: 10px 0 0 0;
    }
  }
}
</style>
