<template>
  <section class="noveltiesadmin">
    <div class="noveltiesadmin__wrapper">
      <div class="noveltiesadmin__title">
        <h2 class="noveltiesadmin__name">{{ title }}</h2>
      </div>
      <div class="noveltiesadmin__section">
        <div
          :class="['noveltiesadmin__route', viewTime[idx].query === $route.query.status ? 'noveltiesadmin__route-active' : '']"
          v-for="(data, idx) in viewTime"
          :key="idx"
          @click="validClick(viewTime[idx].query)"
        >
          {{ data.name }}
        </div>
      </div>
      <div class="noveltiesadmin__sectionFilter" gcard>
        <filterNovelties class="fade-in" :showOther="!showView" :changeFilter="changeFilter"></filterNovelties>
      </div>
      <div class="noveltiesadmin__table" v-if="showView && infoNovelties.length">
        <noveltiesTable class="fade-in" :pagination="getPagination" :usersData="infoNovelties"></noveltiesTable>
      </div>
      <div v-else>
        <tableHistory :infoNovelties="infoNovelties"></tableHistory>
        <button class="spinner click-on-bottom" v-if="!isLoading && hasMore" @click="getPagination"></button>
        <div v-if="isLoading" class="spinner"></div>
      </div>
    </div>
  </section>
</template>

<script>
  import mixinCrudNovelties from "./mixinCrudNovelties";
  import noveltiesTable from "./AdminNoveltiesTableSelected.vue";
  import filterNovelties from "./AdminNoveltiesaFilter.vue";
  import tableHistory from "./AdminTableHistory.vue";
  export default {
    name: "noveltiesAdmin",
    mixins: [mixinCrudNovelties],
    components: {
      tableHistory,
      noveltiesTable,
      filterNovelties,
    },
    data() {
      return {
        title: "NOVEDADES",
        viewTime: [
          { name: "Por aprobar", query: "pending", state: true },
          { name: "Historial", query: "history", state: false },
        ],
        selectTurn: "",
        page: 0,
        infoNovelties: [],
        timeOut: [],
        isLoading: false,
        hasMore: false,
        showView: true,
      };
    },
    methods: {
      async changeFilter() {
        clearTimeout(this.timeOut);
        this.timeOut = setTimeout(async () => {
          this.page = 0;
          this.infoNovelties = [];
          this.isLoading = true;
          const data = await this.getNoveltiesAdmin(`${this.setQuery()}&page=${this.page}`);
          this.infoNovelties = data?.groupByUser || data;
          this.hasMore = data?.hasMore || false;
          this.isLoading = false;
        }, 500);
      },
      async validClick(status) {
        this.infoNovelties = [];
        this.page = 0;
        this.isLoading = true;
        if (status === "history") this.showView = false;
        else this.showView = true;
        this.setFilter({ srcElement: { name: "status", value: status } });
        const data = await this.getNoveltiesAdmin(`${this.setQuery()}&page=${this.page}`);
        this.infoNovelties = data?.groupByUser || data;
        this.hasMore = data?.hasMore || false;
        this.isLoading = false;
      },
      async getPagination() {
        this.isLoading = true;
        this.page++;
        const data = await this.getNoveltiesAdmin(`${this.setQuery()}&page=${this.page}`);
        this.infoNovelties = { ...this.infoNovelties, ...data.groupByUser };
        this.hasMore = data.hasMore;
        this.isLoading = false;
      },

      setQuery() {
        let query = this.$route.query;
        return Object.keys(query)
          .map((key) => `${key}=${query[key]}`)
          .join("&");
      },
    },
    async beforeMount() {
      this.page = 0;
      if (this.$route.query.status) {
        if (this.$route.query.status === "history") this.showView = false;
      } else {
        this.setFilter({ srcElement: { name: "status", value: "pending" } });
      }
      this.isLoading = true;
      const data = await this.getNoveltiesAdmin(`${this.setQuery()}`);
      this.infoNovelties = data?.groupByUser || data;
      this.isLoading = false;
      this.hasMore = data?.hasMore || false;
    },
  };
</script>

<style lang="scss">
  .noveltiesadmin {
    width: 100%;
    &__name {
      font-size: 32px;
    }
    &__section {
      padding: 15px 0 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      width: 100%;
    }
    &__sectionFilter {
      margin-bottom: 15px;
    }
    &__route {
      padding: 5px;
      height: 36px;
      cursor: pointer;
      white-space: nowrap;
      width: 150%;
      border: #d8d8d8 solid 1px;
      background-color: white;
      color: black;
      text-align: center;
      border-radius: 9px 9px 0px 0px;
      &-active {
        background-color: #bd0909;
        color: white;
      }
    }
    &__table {
      box-shadow: 0 2px 5px #d8d8d8;
      background: white;
      border-radius: 6px;
      &-avatar {
        width: 50px;
      }
    }
    &__filter {
      display: flex;
      margin: 5px 0;
    }
  }
</style>
