<template>
  <section v-if="showCard" :class="['cardtableNovelties', select ? 'cardtableNovelties__active' : '']" :set="((assing = cardData.assignament), (who = cardData.whoCreate || defaultUser))">
    <div class="cardtableNovelties__wrapper">
      <div>
        <input class="mycheck cardtableNovelties__checkbox " v-model="select" type="checkbox" @click="action()" />
      </div>
      <div class="cardtableNovelties__information">
        <div class="cardtableNovelties__avatarC">
          <avatar class="cardtableNovelties__avatar" :user="assing"></avatar>
          <p class="cardtableNovelties__name">
            {{ assing.user }}
          </p>
        </div>
        <div class="cardtableNovelties__date">
          {{ this.$global.convertDateToString(cardData.initTransmision) }}
        </div>
        <div class="cardtableNovelties__turn">
          {{ getHoursStart(cardData.initTransmision) }} --
          {{ getHoursStart(cardData.endTransmision) }}
        </div>
        <div class="cardtableNovelties__avatarC">
          <avatar class="cardtableNovelties__avatarMini" :user="who"></avatar>
          <p class="cardtableNovelties__name">
            {{ who.user }}
          </p>
        </div>
        <div class="cardtableNovelties__type">
          <span v-b-tooltip.hover.top="'Detalle'" class="cardtableNovelties__type-detail" @click="showDetail = !showDetail">
            <iconic :name="showDetail ? 'close' : 'eye'" class="cardtableNovelties__select-iconic"></iconic>
          </span>
          <select class="cardtableNovelties__select" v-model="noveltiType" @change="updateNovelties({ _id: cardData._id, type: noveltiType })">
            <option :value="data._id.$oid" v-for="(data, idx) in typesNovietis" :key="idx">
              {{ data.name }}
            </option>
          </select>
        </div>
        <div class="cardtableNovelties__reposition">
          {{ cardData.replenish || "sin info" }}
        </div>
        <div class="cardtableNovelties__editTime">
          <div class="cardtableNovelties__editTime-time">
            <iconic name="clock_solid"></iconic>
            <span>{{ changeTime(cardData.average) }}</span>
          </div>
          <div class="cardtableNovelties__editTime-edit" @click="modifys(cardData)">
            <iconic name="edit"></iconic>
          </div>
        </div>
        <div class="cardtableNovelties__proveOrDisaprove" v-if="!select && false">
          <button class="tableNovelties__icon close" @click="statusChange('rejected')">
            <iconic name="close"></iconic>
          </button>
          <button class="tableNovelties__icon aprove" @click="statusChange('approved')">
            <iconic name="aprove"></iconic>
          </button>
        </div>
      </div>
    </div>
    <div class="cardtableNovelties__description" v-if="showDetail">
      <div class="cardtableNovelties__description-title">
        <h6 class="">Descripción:</h6>
        <span>{{ cardData.description }}</span>
      </div>
      <div class="cardtableNovelties__description-time">
        <h6 class="">Fecha:</h6>
        <span>{{ $global.convertDateToString(cardData.date) }}</span>
      </div>
      <div>
        <h5>Horario de la novedad</h5>
        <span>{{ getHourNoveltie(cardData.date) }} - {{ getHourNoveltie(cardData.dateTo) }}</span>
      </div>
    </div>
  </section>
</template>

<script>
  import mixinCrudNovelties from "./mixinCrudNovelties";
  export default {
    name: "cardNoveltiesAdmin",
    props: ["cardData", "add", "remove", "modify", "selected"],
    mixins: [mixinCrudNovelties],
    data() {
      return {
        select: false,
        noveltiType: "",
        turn: this.$global.dictionary.workShifts_es_auto,
        showDetail: false,
        showCard: true,
        startStreamingData: [],
        defaultUser: { user: "Automático", role: "superadmin" },
      };
    },
    methods: {
      getHourNoveltie(dateISO) {
        const date = new Date(dateISO);
        const options = {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
          timeZone: "America/Bogota",
        };
        const formattedTime = date.toLocaleTimeString("en-US", options);
        return formattedTime;
      },

      changeTime(numeroMinutos) {
        const horas = Math.floor(numeroMinutos / 60);
        const minutos = numeroMinutos % 60;
        const horasFormateadas = horas.toString().padStart(2, "0");
        const minutosFormateados = minutos.toString().padStart(2, "0");

        return `${horasFormateadas}H:${minutosFormateados}M`;
      },
      getHoursStart(date) {
        const hours = new Date(date).getHours();
        const minutes = new Date(date).getMinutes();

        let period = "AM";
        let formattedHours = hours;

        if (hours >= 12) {
          period = "PM";
          formattedHours = hours % 12 || 12;
        }

        const formattedMinutes = minutes.toString().padStart(2, "0");

        return `${formattedHours}:${formattedMinutes} ${period}`;
      },
      action() {
        this.select = !this.select;
        if (this.select) this.add(this.cardData);
        else this.remove(this.cardData);
      },
      modifys(value) {
        this.select = true;
        this.modify(value);
      },
      statusChange(status) {
        const response = this.updateNovelties({ _id: this.cardData._id, status: status, description: "Se actulizo el estado" });
        if (response.error) {
          this.showError(error.message);
          return;
        }
        this.remove(this.cardData);
        this.showCard = false;
      },
    },
    mounted() {
      this.noveltiType = this.cardData.type;
    },
    watch: {
      selected(value) {
        if (value) this.select = true;
        else this.select = false;
      },
    },
  };
</script>
<style lang="scss">
  .cardtableNovelties {
    padding: 0 11px;
    border: 1px solid rgb(240, 240, 240);
    &:hover {
      background: #f5f5f5;
    }
    &__description {
      border: solid 1px #f5f5f5;
      padding: 5px 4px;
    }
    &__select {
      border: none;
      width: 150px;
      background: transparent;
      margin-top: 8px;
      margin-left: 8px;
      outline: none;
      cursor: pointer;
      &-iconic {
        margin-top: 10px;
        &:hover {
          color: $primary-color;
        }
      }
    }
    &__reposition {
      margin: 0 auto;
      padding-top: 8px;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    &__name {
      margin-left: 6px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &__date {
      padding-top: 10px;
    }
    &__wrapper {
      display: flex;
      padding: 15px 20px 5px 5px;
      justify-content: center;
      align-items: center;
      width: 1440px;
    }
    &__turn {
      background-color: #b4afaf;
      margin-top: 8px;
      padding: 2px 10px;
      color: white;
      border-radius: 5px;
      text-transform: capitalize;
      height: 25px;
      width: 180px;
      margin-left: -35px;
    }
    &__information {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      grid-gap: 20px;
      justify-content: space-between;
      &__assignament {
        display: flex;
        align-items: center;
        &__avatar {
          margin-right: 5px;
        }
      }
      &__type {
        font-size: 12px;
        color: #8c8c8c;
        position: relative;
      }
    }
    &__type {
      &-detail {
        cursor: pointer;
      }
    }
    &__checkbox {
      border-radius: 5px;
      border: 1px solid #c4c4c4;
    }
    &__editTime {
      display: flex;
      align-items: center;
      padding: 5px;
      &-time {
        width: 120px;
        .iconic {
          margin: 3px 7px;
        }
        border-radius: 5px;
        padding: 1px;
        border: solid 1px $primary-color;
        color: $primary-color;
      }
      &-content {
        font-size: 14px;
      }
      &-edit {
        margin: auto;
        color: gray;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .iconic {
          font-size: 20px;
        }
      }
    }
    &__avatarC {
      margin: 0 10px;
      display: flex;
      max-height: 50px;
      align-items: center;
    }
    &__avatar {
      width: 38px;
      height: 38px;
    }
    &__avatarMini {
      width: 26px;
      height: 26px;
    }

    &__proveOrDisaprove {
      display: flex;
      .tableNovelties {
        &__icon {
          height: 29px;
          width: 29px;
        }
      }
    }

    &__active {
      background: #f5f5f5;
    }
    .close {
      background-color: #bd0909;
      &:hover {
        background-color: #cc0808;
      }
    }
    .aprove {
      background-color: #0fa90c;
      &:hover {
        background-color: #0cd608;
      }
    }
    [color="Tarde"] {
      background-color: #efb880;
    }
    [color="Noche"] {
      background-color: #b4afaf;
    }
    [color="Mañana"] {
      background-color: #50a2da;
    }
  }
</style>
