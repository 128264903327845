<template>
  <section class="tableHistory">
    <div class="tableHistory__wrapper">
      <div class="tableHistory__tableHistory">
        <div class=" tableHistory__tableHistory-title" bold v-for="(data, idx) in tableLists" :key="idx">{{ data }}</div>
      </div>
      <div class="tableHistory__cards">
        <cardHistory class="fade-in tableHistory__cards" v-for="(data, idx) in infoNovelties" :key="idx" :noveltie="data"></cardHistory>
      </div>
    </div>
  </section>
</template>

<script>
import mixinCrudNovelties from "./mixinCrudNovelties";
import cardHistory from "./AdminCardsHistory";
export default {
  props: ["infoNovelties"],
  mixins: [mixinCrudNovelties],
  components: {
    cardHistory,
  },
  name: "tableHistory",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.tableHistory {
  box-shadow: 0 2px 5px #d8d8d8;
  background: white;
  border-radius: 6px;
  &__cards {
    &:nth-child(odd) {
      background-color: rgba(226, 226, 226, 0.418);
    }
  }
  &__tableHistory {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    flex-wrap: wrap;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(129, 129, 129, 0.335);
    width: 100%;
    :nth-child(1) {
      margin-left: 20px;
    }
    &-title {
      font-size: 14px;
      font-weight: bold;
      color: #4a4a4a;
      padding: 10px 0px;
    }
  }
}
</style>
